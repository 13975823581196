export default [
  {
    key: "imageId",
    initKey: "image",
    label: "field.image",
    type: "async-single-image",
    path: "directory",
    pw: 1400,
    ph: 1050,
    fullWidth: false,
    disableUploadHistory: true,
    compressMineType: "image/png",
    cols: 12,

  },
  {
    key: "nameEn",
    label: "field.nameEn",
    rules: "required|max:100",
    type: "text",
  },
  {
    key: "nameKm",
    label: "field.nameKm",
    rules: "required|max:100",
    type: "text"
  },
  {
    key: "sequenceOrder",
    label: "field.sequenceOrder",
    type: "text"
  },
  {
    cols: 6,
    skip: true,
    hide: true,
  },
  {
    key: "isMain",
    label: "field.main",
    type: "checkbox",
    cols: "auto"
  },

  {
    key: "isEnable",
    label: "field.active",
    type: "checkbox",
    cols: "auto",
  },
]
